import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../app.config.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class OrderOfferService {
  public orders: any[] = [];
  constructor() {
  }

  public pushorder(order: any) {
     let data = {
       price: 0,
       priceAZN: 0,
       totalPrice: 0,
       totalPriceAZN: 0,
       amountOfWeight: 0,
       totalAmountOfWeight: 0,
       deliveryAmount: 0,
       hsCode: '',
       customsInterest: 0,
       customFee: 0,
       additionalCostsInterest: 0,
       additionalCosts: 0,
       basicValue: 0,
       totalBasicValue: 0,
       makeUp: 0,
       minDelivery: 0,
       maxDelivery: 0,
       curency: '',
       mdh: 0,
       ...order,
     };
     this.orders.push(data);
     console.log('service orders push ', this.orders);
     localStorage.setItem('orders', JSON.stringify(this.orders));
  }

  public removeOrder(product: any){
    this.orders = this.orders.filter((order) => order.partNumber !== product.partNumber);
    console.log('service orders remove ', this.orders);
    localStorage.setItem('orders', JSON.stringify(this.orders));
  }
  
}
