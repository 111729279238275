import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from '../app.config.service';
import { BaseResponse } from '../models/base-response';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends BaseService  {
  public list = new BehaviorSubject<any>(null);
  public listCount = new BehaviorSubject<number>(0); 

  public productStockResidueGroups = new BehaviorSubject<any>(null);
  public productStockResidueGroupsCount = new BehaviorSubject<number>(0);
  
  constructor(public environment: AppConfigService, public http: HttpClient, 
    ) {
    super(environment, http); 
  }

  /* getListByParams(path:string, data:any){
    let params = new HttpParams();
    Object.keys(data).forEach((key, index) => {
      if(data[key] !== null && data[key] !== '' && data[key] !== undefined){
        params = params.append(key, data[key]);
      }
    });
    this.getByParams('/report/' + path, params).subscribe((res:BaseResponse) => {
      if(res.status){ 
        this.list.next(res.data.list);
        this.listCount.next(res.data.count)
      }
    })
  }
 */

  getListByParams(path:string, data:any){
    let params = new HttpParams();
    Object.keys(data).forEach((key, index) => {
      if(data[key] !== null && data[key] !== '' && data[key] !== undefined){
        params = params.append(key, data[key]);
      }
    });
    this.getByParams('/report/' + path , params).subscribe((res:BaseResponse) => {
      if(res.status || res.count){ 
        switch (path) {
          case 'alternative-group':
            this.productStockResidueGroups.next(res?.data?.list || res?.list ? res?.data?.list ?? res?.list : []);
            this.productStockResidueGroupsCount.next(res?.data?.count ?? res?.count);
            break; 
          default: 
            this.list.next(res?.data?.list || res?.list ? res?.data?.list ?? res?.list : []);
            this.listCount.next(res?.data?.count ?? res?.count)
            break;
        }  
      }
    })
  }

exportTable(){
  console.log("clickme")
  this.http.get("https://api.ce-parts.az/api/report/productStockResidueExcel").subscribe(data=> {
    const blob = new Blob([data as any], { type: 'application/octet-stream' });
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });
}
/*   resetList(){
    this.list.next(null);
    this.listCount.next(0);
  }
 */
  resetList(path?:string){
    switch (path) {
      case 'productStockResidue':
        this.productStockResidueGroups.next(null);
        this.productStockResidueGroupsCount.next(0);
        break; 
      default: 
      this.list.next(null);
      this.listCount.next(0);
        break;
    }  
  }

  getReserversReport(){
    this.http.get('/api/reserve').subscribe(res=> console.log({res})
    )
  }
}
